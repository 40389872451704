import styles from './Services.module.css'
function Services() {
  return (
    <>
                  <div className={styles.ourServices}>
                 <section id='home'>
                    <h1 className={styles.h1}>НАШІ ПОСЛУГИ</h1>
                    </section>
                    <div className={styles.services}>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i1.png" alt="" />
                          <h3>ДИЗАЙН ІНТЕР'ЄРУ</h3>
                        </div>
                        <p><li>1. Професійний підхід: Наша команда дизайнерів має багаторічний досвід роботи з клієнтами та знає, як створити затишний інтер'єр, який відповідатиме всім вимогам та побажанням клієнта.</li>
<li>2.	Індивідуальний підхід: ми ставимо на перше місце потреби та бажання наших клієнтів, тому кожен проект дизайну інтер'єру розробляється з урахуванням унікальності кожного приміщення та його майбутніх власників.</li>
<li>3.	Ефективне планування: ми створюємо інтер'єр, який відповідає всім функціональним вимогам та забезпечує максимальну зручність та комфорт проживання.</li>
<li>4.	Економія часу та коштів: замовляючи послугу дизайну інтер'єру у нас, ви можете бути впевнені, що ваш проєкт буде реалізовано до 37 днів  та за ринковою ціною.</li>
</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i2.png" alt="" />
                          <h3>АВТОРСЬКИЙ НАГЛЯД</h3>
                        </div>
                        <p><li>Наші експерти знаходяться поруч з клієнтом на кожному етапі ремонту, включаючи вибір і контроль виконання робіт, контроль якості виконання робіт, регулярні огляди прогресу робіт та повідомлення клієнта про будь-які зміни в графіку.</li>
<li>Наші фахівці S. P. STUDIO  забезпечують, що ремонт відбувається згідно зі стандартами якості і з дотриманням затвердженого бюджету. Крім того, авторський нагляд забезпечує безпеку клієнта, оскільки наші експерти відслідковують дотримання технологій та вимог щодо безпеки, який не потребує постійної присутності замовника на об’єкті.</li>
<li>Також, замовляючи авторський нагляд в нашій компанії, клієнт може бути впевненим у тому, що робота виконується в строк та без затримок.</li>
{/* <li>Також, замовляючи авторський нагляд в нашій компанії, клієнт може бути впевненим у тому, що робота виконується в строк та без затримок. Наші експерти завжди на зв'язку і готові допомогти з вирішенням будь-яких питань, що виникають під час ремонту.</li>  <li>Клієнт отримує повну звітність про витрати та виконані роботи, що дозволяє вести точний облік бюджету і уникнути непередбачуваних витрат.</li> */}
{/* <li>Ми пропонуємо нашим клієнтам послугу повного технічного прийому квартири, що дає можливість гарантувати, що всі роботи виконані якісно та відповідно до усіх будівельних стандартів.</li>
<li>Однією з переваг замовлення цієї послуги саме в нашій компанії є наш досвід та професійні знання в галузі будівництва та дизайну. Наші фахівці детально вивчать документацію на об'єкті та проведуть повний комплекс робіт з технічного прийому квартири, забезпечуючи повну відповідність об'єкту всім вимогам та нормативам.</li>
<li>Ми завжди готові відповісти на будь-які ваші запитання та допомогти з вирішенням проблем, пов'язаних з технічним прийомом квартири.Отже, замовляючи послугу технічного прийому квартири від нашої компанії, ви отримуєте повну гарантію якості та відповідальності за проведені роботи, а також допомогу та консультації на кожному етапі взаємодії з нами.</li> */}
</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={35} width={35} src="/images/i6.png" alt="" />
                          <h3>РЕМОНТ КВАРТИР ТА ОСОБНЯКІВ </h3>
                        </div>
                        <p><li>1.	Досвід та професіоналізм: команда S. P. STUDIO має досвід та знання, необхідні для реалізації самих сміливих проєктів. Наші спеціалісти знають, які матеріали вибрати, як вони повинні бути встановлені та в якому порядку, щоб ремонт був виконаний якісно та ефективно.</li>
<li>2.	Економія часу та грошей: Замовляючи ремонт квартири у нашій компанії, кожен клієнт економить свій час, не потрібно шукати та купувати матеріали, шукати робітників тощо. </li>
<li>3.	Якість роботи: Робота команди S. P. STUDIO відповідає вимогам та стандартам якості, що забезпечує надійність та довговічність ремонту. Клієнти можуть бути впевнені, що ремонт буде зроблений професійно та з використанням якісних матеріалів.</li>

</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={30} width={30} src="/images/i5.png" alt="" />
                          <h3>ПІДБІР МАТЕРІАЛІВ ТА ПОСТАЧАННЯ</h3>
                        </div>
                        <p><li>Наші постачальники матеріалів надають постійні знижки та акційні пропозиції, що дозволяє нам забезпечити наших клієнтів найкращими цінами на ринку.</li>
<li>Замовляючи ремонт у нашій компанії та користуючись нашим підбіром матеріалів, клієнти зекономлять свій час та гроші, оскільки вони не будуть витрачати багато часу на самостійний пошук і вибір матеріалів.</li> <li>Наші професіонали знають, які матеріали краще використовувати в кожній конкретній ситуації, і можуть запропонувати оптимальні варіанти для ремонту вашого житла.</li> 
<li>Ви  можете бути впевнені, що отримаєте найкращий сервіс від наших менеджерів та отримаєте приємні враження від покупок.</li>
</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={30} width={30} src="/images/i8.png" alt="" />
                          <h3>  РОЗУМНИЙ БУДИНОК</h3>
                        </div>
                        <p><li>Наша компанія має досвід у проектуванні та впровадженні різноманітних розумних систем в будинках. Ми знаємо, які технології та рішення можуть найкраще підійти для конкретного будинку та його власників.</li>
                        <li>Основними перевагами розумного будинку є: комфорт та економія часу, енергоефективність, безпека та висока функціональність.</li>
<li>S. P. STUDIO пропонує комплексний підхід до проектування та впровадження розумного будинку. Ми можемо забезпечити не тільки встановлення систем, але й їхню інтеграцію та налаштування, щоб забезпечити їх оптимальну роботу та легке управління. Ми допомагаємо клієнтам у вирішенні будь-яких проблем, які виникнуть з системою, та забезпечимо швидке виправлення неполадок.</li>
</p>
                      </div>
                      {/* 
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i5.png" alt="" />
                          <h3>ДИЗАЙН ЕКСТЕР'ЄРУ</h3>
                        </div>
                        <p>Ми об'єднуємось з вами, щоб створити ваш ідеальний план дизайну екстер'єру. Наші експертні поради та рішення дозволять скласти план для задоволення ваших потреб.</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i6.png" alt="" />
                          <h3>ДЕКОРАТИВНІ ПОСЛУГИ</h3>
                        </div>
                        <p>Ми будемо раді допомогти вам змінити внутрішній вигляд вашого будинку, не витрачаючи занадто багато грошей на дизайн інтер’єру.</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i7.png" alt="" />
                          <h3>ОСВІТЛЕННЯ</h3>
                        </div>
                        <p>Наша команда використовує останнє програмне забезпечення для розрахунку та дизайну освітлення, ми створюємо динамічні та інтелектуальні проекти освітлення для наших клієнтів.</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i8.png" alt="" />
                          <h3>АРХІТЕКТУРА ІНТЕР'ЄРУ</h3>
                        </div>
                        <p>Ми вважаємо, що архітектура повинна забезпечувати незабутнє враження. Вихідним моментом є розуміння потреб клієнта та способу використання простору.</p>
                      </div>
                      <div className={styles.service}>
                        <div className={styles.serviceHeader}>
                          <img height={25} width={25} src="/images/i9.png" alt="" />
                          <h3>ДИЗАЙНЕРСЬКІ МЕБЛІ</h3>
                        </div>
                        <p>Іноді речі просто не можна купити в магазинах, а їх потрібно виготовити вручну. Ось чому наші клієнти цінують нас, наші партнери виготовлять меблі під ваше замовлення.</p>
                      </div> */}
                    </div>
                  </div>      
    </>
  );
}
export default Services
